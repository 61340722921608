import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
const Auth = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0()

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Oops... {error.message}</div>
  }
  if (isAuthenticated) {
    return (
      <ul className="list-link">
        <li>
          <Link className="link" to="/arriendoasegurado">
            Arriendoasegurado
          </Link>
        </li>
        <li>
          <Link className="link" to="/billing">
            Billing
          </Link>
        </li>
        <li>
          <Link className="link" to="/demand">
            Demand
          </Link>
        </li>
        <li>
          <Link className="link" to="/properties">
            Properties
          </Link>
        </li>
        <li>
          <Link className="link" to="/propietary">
            Propietary
          </Link>
        </li>
        <li>
          <Link className="link" to="/scheduler">
            Scheduler
          </Link>
        </li>
      </ul>
    )
  } else {
    return <button onClick={loginWithRedirect}>Log in</button>
  }
}
export default Auth
