import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Auth from './pages/Auth'
import Login from './pages/Login'
import ArriendoAsegurado from './pages/ArriendoAsegurado'
import Billing from './pages/Billing'
import Demand from './pages/Demand'
import Propietary from './pages/Propietary'
import Properties from './pages/Properties'
import Scheduler from './pages/Scheduler'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/auth" element={<Auth />} />
        <Route
          exact
          path="/arriendoasegurado"
          element={<ArriendoAsegurado />}
        />
        <Route exact path="/billing" element={<Billing />} />
        <Route exact path="/demand" element={<Demand />} />
        <Route exact path="/properties" element={<Properties />} />
        <Route exact path="/propietary" element={<Propietary />} />
        <Route exact path="/scheduler" element={<Scheduler />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
