import 'swagger-ui/dist/swagger-ui.css'
import { useAuth0 } from '@auth0/auth0-react'
import Swagger from '../components/Swagger'
const Auth = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0()

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Oops... {error.message}</div>
  }
  if (isAuthenticated) {
    return <Swagger app="scheduler" />
  } else {
    return <button onClick={loginWithRedirect}>Log in</button>
  }
}
export default Auth
