import { useEffect, useState } from 'react'
import SwaggerUI from 'swagger-ui'
import useGetToken from '../auth/GetToken'
import 'swagger-ui/dist/swagger-ui.css'
import axios from 'axios'
import { BASE_URL } from '../env'
const Swagger = (props) => {
  const { getToken } = useGetToken()
  const [IsSwagger, setIsSwagger] = useState(false)

  useEffect(() => {
    getToken().then((authToken) => {
      axios
        .get(`${BASE_URL}/${props.app}/swagger/?format=openapi`, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          setIsSwagger(true)
          let ui = SwaggerUI({
            spec: response.data,
            filter: true,
            dom_id: '#swagger-ui',
            onComplete: () => {
              ui.preauthorizeApiKey('Bearer', `Bearer ${authToken}`)
            },
          })
        })
    })
    // save all Promises as array
  })
  return (
    <div>{IsSwagger ? <div id="swagger-ui"></div> : <div>Loading...</div>}</div>
  )
}
export default Swagger
