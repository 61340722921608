import AppRouter from './route'

function App() {
  return (
    <div className="App">
      <AppRouter></AppRouter>
    </div>
  )
}

export default App
