import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0_AUDIENCE } from '../env'

const useGetToken = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
      })
      setLoading(false)
      return token
    } catch (err) {
      setLoading(false)
      throw err
    }
  }

  return { loading, getToken }
}
export default useGetToken
